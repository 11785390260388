import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { throwError, Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Renderer, setOptions, parse } from 'marked'

@Injectable({
  providedIn: 'root'
})
export class NgxMdService {
  private _renderer: any = new Renderer();
  constructor(private _http:HttpClient) {
    this.extendRenderer();
    this.setMarkedOptions({});
  }

  // get the content from remote resource
  getContent(path: string):Observable<any> {
    return this._http.get(path, {responseType: 'text'}).pipe(map(res => res))
      .pipe(catchError(this.handleError));
  }

  get renderer() {
     return this._renderer;
  }

  setMarkedOptions(options: any) {
    options = Object.assign({
       gfm: true,
       tables: true,
       breaks: false,
       pedantic: false,
       sanitize: false,
       smartLists: true,
       smartypants: false
    }, options);
    options.renderer = this._renderer;
     
    setOptions(options);
  }

   // comple markdown to html
  compile(data: string) {
    return parse(data);
  }

   // handle error
  private handleError(error: any): any {
    let errMsg: string;
    if (error instanceof fetch) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

   // extend marked render to support todo checkbox
  private extendRenderer() {
    this._renderer.listitem = function(text: string) {
      if (/^\s*\[[x ]\]\s*/.test(text)) {
      text = text
        .replace(/^\s*\[ \]\s*/, '<input type="checkbox" style=" vertical-align: middle; margin: 0 0.2em 0.25em -1.6em; font-size: 16px; " disabled> ')
        .replace(/^\s*\[x\]\s*/, '<input type="checkbox" style=" vertical-align: middle; margin: 0 0.2em 0.25em -1.6em; font-size: 16px; " checked disabled> ');
          return '<li style="list-style: none">' + text + '</li>';
        } else {
          return '<li>' + text + '</li>';
        }
    };
  }
}
