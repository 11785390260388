import { Component, ElementRef, Input, PLATFORM_ID, Inject, AfterViewInit } from '@angular/core';
import { NgxMdService } from './ngx-md.service';
import { isPlatformBrowser } from '@angular/common';
import * as Prism from 'prismjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ngx-md, markdown, [Markdown],[NgxMd]',
    template: '<ng-content></ng-content>',
    styles: [
        `.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
            background: none;
        }`
    ]
})
export class NgxMdComponent implements AfterViewInit {
    private _path: string;
    private _data: string;
    private _md: any;
    private _ext: string;

    constructor(
        private _mdService: NgxMdService,
        private _el: ElementRef,
        @Inject(PLATFORM_ID) private _platformId: string
    ) { }
   

    @Input()
    set path(value: string) {
      if (value) {
        this._path = value;
        this.onPathChange();
      }
    }

    @Input()
    set data(value: string) {
      if (value) {
        this._data = value;
        this.onDataChange(value);
      }
    }

    // on content change
    ngAfterViewInit() {
        this._md = this._mdService.compile(this._el.nativeElement.innerHTML)
        this._el.nativeElement.innerHTML = this._mdService.compile(this._md);
        this.highlightContent(false);
    }

    // on input
    onDataChange(data: string) {
      if (data) {
          this._md = this.prepare(decodeHtml(data));
          this._md = this._mdService.compile(data)
        this._el.nativeElement.innerHTML = this._mdService.compile(this._md);
      } else {
        this._el.nativeElement.innerHTML = '';
      }
      this.highlightContent(false);
    }

    /**
     * get remote conent;
     */
    onPathChange() {
        this._ext = this._path && this._path.split('.').splice(-1).join();
        this._mdService.getContent(this._path)
            .subscribe(data => {
                this._md = this._ext !== 'md' ? '```' + this._ext + '\n' + data + '\n```' : data;
                this._el.nativeElement.innerHTML = this._mdService.compile(this.prepare(this._md));
                this.highlightContent(false);
            });
    }

    /**
     * catch http error
     */
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    processRaw(data) {
        this._md = this.prepare(decodeHtml(this._el.nativeElement.innerHTML));
        this._el.nativeElement.innerHTML = this._mdService.compile(this._md);
        this.highlightContent(false);
    }


    /**
     * Prepare string
     */
    prepare(raw: string) {
        if (!raw) return '';
        if (this._ext === 'md' || !this.path) {
            let isCodeBlock = false;
            return raw.split('\n').map((line: string) => {
                if (this.trimLeft(line).substring(0, 3) === '```') {
                    isCodeBlock = !isCodeBlock;
                }
                return isCodeBlock ? line : line.trim();
            }).join('\n');
        }
        return raw.replace(/\"/g, '\'');
    }

    /**
     * Trim left whitespace
     */
    private trimLeft(line: string) {
        return line.replace(/^\s+|\s+$/g, '');
    }

    /**
     * Use Prism to highlight code snippets only on the browser
     */
    private highlightContent(async: boolean): void {
      if (isPlatformBrowser(this._platformId)) {
        Prism.highlightAll(async);
      }
    }
}

function decodeHtml(html: string) { // https://stackoverflow.com/a/7394787/588521
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}
